import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import AssessmentResultBalanceStats from 'assessments/components/AssessmentResultBalanceStats'
import Progress from 'components/Progress'
import Row from 'components/Row'
import Text from 'components/Text'
import assessments from 'libs/assessments'
import styles from './UserStats.module.scss'

type Props = {
  assessmentInternalId?: string
  assessmentSteps?: {
    isCompleted: boolean
    title: string
  }[]
  // TODO: mv to libs/assessments post ts
  assessmentType: 'balance' | 'movement' | 'reps' | 'timed'
  minRecord: number
  maxRecord: number
  points: number
  userRecord: number
}

type State = {
  componentWidth: number
}

export default class UserStats extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      componentWidth: 660,
    }
    this.barRef = React.createRef()
  }

  componentDidMount() {
    if (this.barRef.current) {
      this.setState({ componentWidth: this.barRef.current.offsetWidth })
    }
  }

  componentDidUpdate() {
    if (this.barRef.current && this.barRef.current.offsetWidth !== this.state.componentWidth) {
      this.setState({ componentWidth: this.barRef.current.offsetWidth })
    }
  }

  render() {
    if (this.props.assessmentInternalId === assessments.ASSESSMENT_BALANCE_INTERNAL_ID) {
      return (
        <AssessmentResultBalanceStats
          points={this.props.points}
          poses={this.props.assessmentSteps}
          className="UserStats"
        />
      )
    }

    const total = this.props.minRecord + this.props.maxRecord // assume minRecord length beyond maxRecord is the max
    const value = this.props.userRecord
    let userRecordPosition = (this.props.userRecord / total) * this.state.componentWidth
    if (userRecordPosition > this.state.componentWidth) {
      userRecordPosition = this.state.componentWidth
    }

    const labels = ['Warming up', 'Getting better', 'Great']

    return (
      <div ref={this.barRef} className={classNames('UserStats', styles.this)}>
        <Row size="xsmall">
          <div className={styles['chart-labels']}>
            <Text
              align="right"
              size="small"
              weight="semibold"
              className={styles['chart-labels--item']}>
              {this.props.assessmentType === assessments.ASSESSMENT_TYPE_TIMED
                ? `${this.props.maxRecord}s`
                : this.props.minRecord}
            </Text>
            <div className={styles['chart-labels--item']} />
            <Text
              align="left"
              size="small"
              weight="semibold"
              className={styles['chart-labels--item']}>
              {this.props.assessmentType === assessments.ASSESSMENT_TYPE_TIMED
                ? `${this.props.minRecord}s`
                : this.props.maxRecord}
            </Text>
          </div>
        </Row>
        <Row size="small">
          <Progress value={value} total={total}>
            <div
              style={{
                left: userRecordPosition,
              }}
              className={styles.marker}>
              <Text
                color="white"
                size="small"
                weight="semibold"
                wrap={false}
                className={classNames(
                  styles['marker--box'],
                  styles[
                    `marker--box---${
                      (userRecordPosition / this.state.componentWidth) * 100 > 50 ? 'right' : 'left'
                    }`
                  ]
                )}>
                <Text casing="upper">
                  Your{' '}
                  {this.props.assessmentType === assessments.ASSESSMENT_TYPE_TIMED
                    ? 'Time'
                    : 'Score'}
                </Text>{' '}
                {_.round(this.props.userRecord, 2)}
                {this.props.assessmentType === assessments.ASSESSMENT_TYPE_TIMED ? 's' : ''}
              </Text>
            </div>
          </Progress>
        </Row>
        <div className={styles['chart-labels']}>
          {labels.map((item) => (
            <Text
              align="center"
              casing="upper"
              key={item}
              size="xsmall"
              weight="semibold"
              className={styles['chart-labels--item']}>
              {item}
            </Text>
          ))}
        </div>
      </div>
    )
  }
}
